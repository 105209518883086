import { iassetbundleApi } from '@deepup/internal-apis';
import { Paper, type Theme } from '@mui/material';
import { DataGrid, type GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { RelevantBundlesKey } from '@hooks/useApiAssetBundler';

import { UpdateButton } from '../UpdateButton';

import { PhotoAssetCell, ScanAssetCell } from './TableCell';

type Props = {
  assets: iassetbundleApi.AssetReference[];
  bundleKey: RelevantBundlesKey;
};

export const AssetReviewTable = ({ assets, bundleKey }: Props) => {
  const { t } = useTranslation();
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const columns: GridColDef<iassetbundleApi.AssetReference>[] = [
    {
      disableColumnMenu: true,
      field: 'id',
      filterable: false,
      headerName: t('components.assetReview.table.asset'),
      hideable: true,
      width: 100,
      sortable: false,
      type: 'string',
      renderCell: (params) => {
        if (params.row.type === iassetbundleApi.AssetType.PHOTO) {
          return <PhotoAssetCell id={params.row.id} />;
        }
        if (params.row.type === iassetbundleApi.AssetType.SCAN) {
          return <ScanAssetCell id={params.row.id} />;
        }

        return <></>;
      },
    },
    {
      disableColumnMenu: true,
      field: 'type',
      filterable: false,
      sortable: false,
      headerName: t('components.assetReview.table.type'),
      hideable: true,
      flex: 1.5,
      type: 'string',
      cellClassName: 'centered-cell',
      renderCell: (params) => {
        if (params.row.type === iassetbundleApi.AssetType.PHOTO) {
          return <>Photo</>;
        }
        if (params.row.type === iassetbundleApi.AssetType.SCAN) {
          return <>Scan</>;
        }

        return <>None</>;
      },
    },
  ];

  return (
    <Paper elevation={1} sx={{ height: (theme: Theme) => `calc(100vh - ${theme.spacing(52)})` }}>
      <DataGrid
        checkboxSelection
        columns={columns}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        onRowSelectionModelChange={(selectionModel) =>
          setSelectedAssetIds(selectionModel as string[])
        }
        rowHeight={72}
        rows={assets}
        slots={{
          toolbar: () => <UpdateButton bundleKey={bundleKey} selectedAssetIds={selectedAssetIds} />,
        }}
        sx={{
          '& .centered-cell': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      />
    </Paper>
  );
};
