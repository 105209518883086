import { useAuth0 } from '@auth0/auth0-react';
import { GlobalHeader } from '@deepup/global-header';
import { AppBar, Stack, Toolbar, useTheme } from '@mui/material';
import flagsmith from 'flagsmith';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { FeatureFlag } from '@components/FeatureFlag';
import { TabsRouter } from '@components/TabsRouter';
import { useConfig } from '@hooks/useConfig';
import { useFeatureFlagIdentity } from '@hooks/useFeatureFlagIdentity';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { trackEvent } from '@utils/trackEvent';

export const Layout = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth0();
  const feature_flags = flagsmith.getAllFlags();
  const { isAssetReviewEnabled } = useFeatureFlags();
  const { layoutContainerStyle } = useConfig();
  const theme = useTheme();

  useEffect(() => {
    trackEvent('userEmailDomain', {
      email_domain: user?.email ? user.email.split('@')[1] ?? 'unknown' : 'unknown',
    });
    trackEvent('featureFlags', {
      feature_flags,
    });
  }, [feature_flags, user]);

  // identify user in flagsmith
  useFeatureFlagIdentity(user);

  // TODO: for router tabs see: https://mui.com/material-ui/integrations/routing/#tabs

  return (
    <>
      <GlobalHeader
        language={i18n.language as 'de' | 'en' | undefined}
        languageSelectors={[{ key: 'de' }, { key: 'en' }]}
        onChangeLanguage={(selectedLanguage) => {
          i18n.changeLanguage(selectedLanguage);
        }}
        showLanguageToggle
        showThemeToggle
        title={t('title')}
      />
      <FeatureFlag name="cockpit_migration_enabled">
        {({ Badge }) => {
          const tabs = [
            { label: t('pages.layout.mapTabLabel'), to: '/map' },
            { label: <Badge>{t('pages.layout.photoTabLabel')}</Badge>, to: '/photo' },
            { label: <Badge>{t('pages.layout.projectTabLabel')}</Badge>, to: '/project' },
          ];
          const assetReviewTab = {
            label: <Badge>{t('pages.layout.assetReviewTabLabel')}</Badge>,
            to: '/assetReview',
          };

          const tabsToRender = isAssetReviewEnabled ? [...tabs, assetReviewTab] : tabs;

          return (
            <AppBar
              component="nav"
              sx={(theme) => ({
                zIndex: theme.zIndex.drawer + 1,
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14)',
                borderRadius: 0,
                padding: 0,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.getContrastText(theme.palette.background.paper),
                borderTop: `1px solid ${theme.palette.divider}`,
                // top because of fixed global header
                top: { xs: '56px', sm: '80px' },
              })}
            >
              <Toolbar sx={{ gap: '20px' }}>
                <TabsRouter
                  items={tabsToRender}
                  // same height as Toolbar
                  sx={{ height: { xs: '56px', sm: '64px' } }}
                />
              </Toolbar>
            </AppBar>
          );
        }}
      </FeatureFlag>
      <Stack
        sx={{
          height: '100%',
          background: theme.palette.background.default,
          color: theme.palette.text.primary,
          ...layoutContainerStyle,
        }}
      >
        <Outlet />
      </Stack>
    </>
  );
};
