import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetReviewTable } from '@components/AssetReview/Table/Table';
import {
  useListAssetsInBundle,
  useRelevantBundles,
  type RelevantBundlesKey,
} from '@hooks/useApiAssetBundler';

export function Page() {
  const { t } = useTranslation();
  const [currentBundleKey, setCurrentBundleKey] = useState<RelevantBundlesKey>('included');
  const { data: relevantBundles, error: relevantBundlesError } = useRelevantBundles('mockId');
  const { data, error } = useListAssetsInBundle(relevantBundles?.[currentBundleKey] || null);

  useEffect(() => {
    if (error || relevantBundlesError) {
      enqueueSnackbar(t('components.assetReview.error'), { variant: 'error' });
    }
  }, [error, relevantBundlesError, t]);

  return (
    <Stack
      direction="column"
      sx={(theme) => ({
        margin: '2rem auto',
        width: '90%',
        [theme.breakpoints.up('md')]: {
          maxWidth: '50%',
        },
      })}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between" mb={2}>
        <Typography fontWeight="bold" gutterBottom variant="h6">
          {t('pages.assetReview.title')}
        </Typography>
        <FormControl>
          <InputLabel id="asset-state-select-label">
            {t('components.assetReview.select.label')}
          </InputLabel>
          <Select
            id="asset-state-select"
            label={t('components.assetReview.select.label')}
            labelId="asset-state-select-label"
            onChange={(newVal) => setCurrentBundleKey(newVal.target.value as RelevantBundlesKey)}
            value={currentBundleKey}
          >
            <MenuItem value={'included'}>
              {t('components.assetReview.bundleKeys.included')}
            </MenuItem>
            <MenuItem value={'excluded'}>
              {t('components.assetReview.bundleKeys.excluded')}
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <AssetReviewTable assets={data || []} bundleKey={currentBundleKey} />
    </Stack>
  );
}
