import type { ReactNode } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { Page as PageAssetReview } from '@pages/assetReview/page';
import { Layout as LayoutBilling } from '@pages/billing/layout';
import { Page as PageBilling } from '@pages/billing/page';
import { Error as ErrorRoot } from '@pages/error';
import { Layout as LayoutRoot } from '@pages/layout';
import { Page as PageMapFeature } from '@pages/map/feature/[featureId]/page';
import { Page as PageMap } from '@pages/map/page';
import { Page as PageMapPhoto } from '@pages/map/photo/[photoId]/page';
import { Page as PageMapTrajectory } from '@pages/map/scan/[scanId]/[elementType]/page';
import { Page as PageMapSegment } from '@pages/map/segment/[segmentId]/page';
import { Page as PageListPhoto } from '@pages/photo/page';
import { Page as PageProject } from '@pages/project/page';

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/map',
  children,
}: {
  isAllowed: boolean;
  redirectPath?: string;
  children?: ReactNode;
}) => {
  if (!isAllowed) {
    return <Navigate replace to={redirectPath} />;
  }

  return children ? children : <Outlet />;
};

export const AppRoutes = () => {
  const { isBillingEnabled, isCockpitMigrationEnabled, isAssetReviewEnabled } = useFeatureFlags();

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LayoutRoot />} path="/">
          <Route element={<Navigate to="map" />} index />
          <Route element={<PageMap />} path="map">
            <Route element={<PageMapTrajectory />} path="scan/:scanId/:elementType/:tab?" />
            <Route element={<PageMapPhoto />} path="photo/:photoId/:photoIndex" />
            <Route element={<PageMapSegment />} path="segment/:segmentId" />
            <Route element={<PageMapFeature />} path="feature/:featureId" />
          </Route>
          <Route element={<ProtectedRoute isAllowed={isBillingEnabled} />}>
            <Route element={<LayoutBilling />}>
              <Route element={<PageBilling />} path="billing" />
            </Route>
          </Route>
          <Route element={<ProtectedRoute isAllowed={isCockpitMigrationEnabled} />}>
            <Route element={<PageProject />} path="project" />
            <Route element={<PageListPhoto />} path="photo" />
          </Route>
          <Route element={<ProtectedRoute isAllowed={isAssetReviewEnabled} />}>
            <Route element={<PageAssetReview />} path="assetReview" />
          </Route>
          <Route element={<ErrorRoot />} path="*" />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
