import {
  MapOutline,
  MapFilled,
  PhotoOutline,
  PhotoFilled,
  ProjectCollectionFilled,
  ProjectCollectionOutline,
  SearchCircleOutline,
} from '@deepup/icons';
import { Tab as MuiTab, Tabs as MuiTabs, type TabsProps as MuiTabsProps } from '@mui/material';
import type { SyntheticEvent, ReactNode } from 'react';
import { useLocation, Link } from 'react-router-dom';

const useRouteMatch = (patterns: readonly string[]) => {
  const { pathname } = useLocation();

  const path = pathname.split('/').slice(1)[0];

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i].replace(/^\//, '');

    if (pattern === path) {
      return patterns[i];
    }
  }

  return null;
};

type TabItem = { label: ReactNode; to: string };

export type TabsRouterProps = {
  items: TabItem[];
  ariaLabel?: string;
  onChange?: (path: string) => void;
  sx?: MuiTabsProps['sx'];
};

const getTabIcon = (to: TabItem['to'], current: string) => {
  const iconProps = {
    fill: 'currentColor',
    height: '24px',
    width: '24px',
  };

  const tabIcons = {
    '/photo': <PhotoOutline {...iconProps} />,
    '/map': <MapOutline {...iconProps} />,
    '/project': <ProjectCollectionOutline {...iconProps} />,
    '/assetReview': <SearchCircleOutline {...iconProps} />,
  };

  const tabIconsCurrent = {
    '/photo': <PhotoFilled {...iconProps} />,
    '/map': <MapFilled {...iconProps} />,
    '/project': <ProjectCollectionFilled {...iconProps} />,
    '/assetReview': <SearchCircleOutline {...iconProps} />,
  };
  const currentTab = current as keyof typeof tabIcons;
  const linkTab = to as keyof typeof tabIcons;

  if (current === to && currentTab in tabIconsCurrent) {
    return tabIconsCurrent[currentTab];
  } else if (linkTab in tabIcons) {
    return tabIcons[linkTab];
  }
};

const a11yProps = (index: number) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
};

export const TabsRouter = ({ items, ariaLabel, onChange, sx }: TabsRouterProps) => {
  const currentTab = useRouteMatch(items.map((item) => item.to));

  const handleChange = (_: SyntheticEvent, path: string) => {
    onChange?.(path);
  };

  if (currentTab === null) {
    return null;
  }

  return (
    <MuiTabs aria-label={ariaLabel} onChange={handleChange} sx={sx} value={currentTab}>
      {items.map(({ label, to }, index) => (
        <MuiTab
          component={Link}
          icon={getTabIcon(to, currentTab)}
          iconPosition="start"
          key={index}
          label={label}
          sx={sx}
          to={to}
          value={to}
          {...a11yProps(index)}
        />
      ))}
    </MuiTabs>
  );
};
