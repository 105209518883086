import { PhotoFilled } from '@deepup/icons';
import { Button, Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';

import { ImageWithSource } from '@components/Image';
import { useApiTopDownView } from '@hooks/useApiTopDownView';
import { useImageSource, type ImageSourceUrl } from '@hooks/useImageSource';

const ToolTipImage = ({
  id,
  url,
  isError,
}: {
  id: string;
  url: ImageSourceUrl;
  isError: boolean;
}) => {
  return (
    <Tooltip
      arrow
      placement="right"
      title={
        <ImageWithSource
          alt={`${t('pages.map.photo.imageAltCategory')}:${id}`}
          height={500}
          imageSource={url}
          sx={{
            width: '100%',
            height: '100%',
            display: 'block',
          }}
          sxLoading={{
            width: '100%',
            height: '500px',
          }}
        />
      }
    >
      <Button
        onClick={typeof url === 'string' ? () => window.open(url, '_blank') : undefined}
        sx={{
          height: 60,
        }}
        variant="text"
      >
        {!isError ? (
          <ImageWithSource
            alt={`${t('pages.map.photo.imageAltCategory')}:${id}`}
            height={60}
            imageSource={url}
            sx={{
              width: '100%',
              height: '100%',
              display: 'block',
            }}
            sxLoading={{
              width: '100%',
              height: '60px',
            }}
          />
        ) : (
          <PhotoFilled />
        )}
      </Button>
    </Tooltip>
  );
};

export const PhotoAssetCell = ({ id }: { id: string }) => {
  const url = useImageSource({ id });

  const isError = url instanceof Error;

  return <ToolTipImage id={id} isError={isError} url={url} />;
};

export const ScanAssetCell = ({ id }: { id: string }) => {
  const { data, error } = useApiTopDownView(id);

  return <ToolTipImage id={id} isError={!!error} url={data?.url || ''} />;
};

export const MultilineText = ({ text }: { text?: string | null }) => {
  if (!text) return null;

  return (
    <Typography variant="body2" whiteSpace="normal">
      {text}
    </Typography>
  );
};
