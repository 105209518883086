import { iassetbundleApi } from '@deepup/internal-apis';
import { useQuery } from '@tanstack/react-query';

export const useListAssetsInBundle = (bundleId: string | null) => {
  return useQuery({
    queryKey: ['iassetBundleClient.listAssetsInBundle', bundleId],
    queryFn: async () => {
      if (bundleId) {
        const includedAssetsMock = [
          { id: '11f643d6-c3a8-4258-a6a6-50e0d36eae36', type: iassetbundleApi.AssetType.PHOTO },
          { id: '326142d0-ffe7-4225-884b-549e1eaaaa95', type: iassetbundleApi.AssetType.PHOTO },
          { id: '8d223dc4-02aa-4459-af1c-beaf78c57ca2', type: iassetbundleApi.AssetType.PHOTO },
          { id: '0434f200-2320-4693-8e07-a8ee658a96bd', type: iassetbundleApi.AssetType.PHOTO },
          { id: 'ec729f5b-cedc-43bb-83a4-8aa118c65a98', type: iassetbundleApi.AssetType.SCAN },
          { id: 'c65c78de-2559-4b76-88e1-fdcfa98fd402', type: iassetbundleApi.AssetType.SCAN },
          { id: '06c79638-0a54-4acf-aac2-64da8157c290', type: iassetbundleApi.AssetType.SCAN },
          { id: '6f05711d-8a61-4831-91c6-f0466481fdbe', type: iassetbundleApi.AssetType.SCAN },
        ];
        const excludeddAssetsMock = [
          { id: '1fd187b7-eef8-4d2f-b210-e376dca59cb9', type: iassetbundleApi.AssetType.PHOTO },
          { id: 'd374bb50-7871-4a8a-ae78-fad4c523e32a', type: iassetbundleApi.AssetType.SCAN },
          { id: '4e8613c9-1f6f-4a28-ad20-5603b73f6d05', type: iassetbundleApi.AssetType.SCAN },
        ];

        return bundleId === 'includedId' ? includedAssetsMock : excludeddAssetsMock;
      }
    },
    enabled: !!bundleId,
  });
};

export const possibleBundlesKeys = ['included', 'excluded'] as const;
export type RelevantBundlesKey = (typeof possibleBundlesKeys)[number];

export const useRelevantBundles = (organizationId: string) => {
  return useQuery({
    queryKey: ['iassetBundleClient.getRelevantBundles', organizationId],
    queryFn: async () => {
      if (organizationId) {
        const relevantBundles = {
          included: 'includedId',
          excluded: 'excludedId',
        };

        return relevantBundles;
      }
    },
    enabled: !!organizationId,
  });
};

export const updateAssets = async (assetIds: string[], bundleKey: RelevantBundlesKey) => {
  // eslint-disable-next-line no-console
  console.log('Updating', assetIds, ' from', bundleKey, ' bundle to the other bundle');
  // throw new Error('Failed to update assets');
};
