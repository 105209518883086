import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateAssets, type RelevantBundlesKey } from '@hooks/useApiAssetBundler';

type UpdateButtonProps = {
  selectedAssetIds: string[];
  bundleKey: RelevantBundlesKey;
};

export const UpdateButton = ({ selectedAssetIds, bundleKey }: UpdateButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const update = async () => {
    try {
      await updateAssets(selectedAssetIds, bundleKey);
      setOpen(false);
    } catch (e) {
      enqueueSnackbar(t('components.assetReview.error'), { variant: 'error' });
    }
  };

  return (
    <Fragment>
      <Box component="div" display={'flex'} py={1}>
        <Button
          disabled={selectedAssetIds.length === 0}
          onClick={handleClickOpen}
          size="small"
          variant="contained"
        >
          {selectedAssetIds.length === 0
            ? t('components.assetReview.button.empty')
            : t(`components.assetReview.button.${bundleKey}`, { count: selectedAssetIds.length })}
        </Button>
      </Box>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">
          {t('components.assetReview.dialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(`components.assetReview.dialog.${bundleKey}`, { count: selectedAssetIds.length })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('components.assetReview.dialog.cancel')}</Button>
          <Button onClick={update}>{t('components.assetReview.dialog.confirm')}</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
